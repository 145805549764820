module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-image/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-gtag/gatsby-browser.js'),
      options: {"plugins":[],"trackingIds":["GA-TRACKING_ID","AW-CONVERSION_ID","DC-FLOODIGHT_ID"],"gtagConfig":{"optimize_id":"OPT_CONTAINER_ID","anonymize_ip":true,"cookie_expires":0},"pluginConfig":{"head":false,"respectDNT":true,"exclude":["/preview/**","/do-not-track/me/too/"],"origin":"YOUR_SELF_HOSTED_ORIGIN","delayOnRouteUpdate":0}},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Cyrek Ventures","short_name":"CV","start_url":"/pl","background_color":"#F39200","theme_color":"#F39200","lang":"pl","description":"Akcelerator biznesu dla (nie)startupów | Cyrek Ventures","icon":"src/images/favicon.png","icons":[{"src":"src/images/android-chrome-512x512.png","sizes":"512x512","type":"image/png","purpose":"any"},{"src":"src/images/android-chrome-512x512.png","sizes":"512x512","type":"image/png","purpose":"maskable"}],"localize":[{"start_url":"/en","lang":"en","name":"Cyrek Ventures","short_name":"CV","description":"Business accelerator for (non)startups | Cyrek Ventures "}],"display":"standalone","crossOrigin":"anonymous","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","include_favicon":true,"cacheDigest":"1f456437cd95d836c3c93d692742fab9"},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
